// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\Public\\Documents\\portfolio_2018\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-about-js": () => import("C:\\Users\\Public\\Documents\\portfolio_2018\\src\\templates\\about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blog-js": () => import("C:\\Users\\Public\\Documents\\portfolio_2018\\src\\templates\\blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-photography-js": () => import("C:\\Users\\Public\\Documents\\portfolio_2018\\src\\templates\\photography.js" /* webpackChunkName: "component---src-templates-photography-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\Public\\Documents\\portfolio_2018\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\Public\\Documents\\portfolio_2018\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photography-js": () => import("C:\\Users\\Public\\Documents\\portfolio_2018\\src\\pages\\photography.js" /* webpackChunkName: "component---src-pages-photography-js" */)
}

exports.data = () => import("C:\\Users\\Public\\Documents\\portfolio_2018\\.cache\\data.json")

